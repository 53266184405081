svg.custom path.link {
  fill: none;
  stroke: #382e92;
  stroke-width: 1.5px; }

svg.custom .mother image {
  filter: hue-rotate(90deg); }

svg.custom text {
  font-size: 16px;
  font-weight: 600;
  fill: #382e92; }

svg.custom .inbred image {
  filter: drop-shadow(0px 0px 4px red); }

svg.custom .inbred.mother image {
  filter: hue-rotate(90deg) drop-shadow(0px 0px 4px red); }

svg.custom .singlechild text {
  transform: translate(0px, -12px); }
