.error {
  background-color: rgba(56, 46, 146, 0.15);
  height: 90vh;
  padding-top: 20vh; }
  .error .oisann-container {
    position: relative;
    padding-top: 6%;
    padding-bottom: 6%;
    width: 90%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    margin: 0 auto; }
  .error .oisann-content {
    display: flex;
    align-items: center;
    flex-direction: column; }
  .error .x-container {
    position: absolute;
    top: 2%;
    right: 2%; }

.navbar {
  display: flex;
  justify-content: space-between;
  padding-left: 4%;
  padding-right: 4%;
  height: 10vh;
  padding-bottom: 10px;
  background-color: transparent; }
  .navbar img.logo {
    width: 50px;
    height: 50px;
    margin-top: 20px; }
  .navbar a.logo {
    width: 50px;
    height: 50px; }
  .navbar img.burger {
    width: 25px; }
  .navbar .icon-button {
    z-index: 10;
    width: 50px;
    height: 50px; }

.registration .main-headliner {
  margin-top: -50px;
  width: 116;
  height: 22px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: var(--blueberry);
  text-align: center; }

.registration .centered-headliner {
  margin-top: 2%;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: var(--blueberry);
  margin-bottom: 30px; }

.registration .dog-img {
  box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2);
  width: 125%;
  height: auto; }

.registration .dog-gender-header {
  margin-top: 5%;
  color: var(--slate); }

.registration .dog-gender-container {
  margin-bottom: 10%;
  color: var(--slate); }

.registration .registration-form {
  padding: 0 10%;
  width: 100%;
  margin-bottom: 70px;
  margin-top: 3vh;
  background-color: #ffffff; }

.registration .dogpicture-container {
  position: relative;
  padding-top: 6%;
  width: 347px;
  height: 345px;
  border-radius: 10px;
  background-color: #ffffff;
  margin: 0 auto; }

.registration .dogpicture-content {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column; }

.registration .picture-button-container {
  position: relative; }

.registration .add-spacing {
  margin-top: 5%;
  width: 100%;
  text-align: center; }

.registration .add-more-animals-button {
  left: -12%;
  width: 124%;
  position: absolute; }

.registration .link-placement {
  text-decoration: underline;
  margin-top: 5%;
  font-size: 13px; }

.registration .button-container {
  position: relative;
  margin-top: 25px; }

.registration .button-placement {
  position: absolute;
  right: -50px;
  top: -70px; }

.registration .create-button {
  margin-top: 12px;
  width: 200px !important;
  height: 40px;
  border-radius: 35px;
  padding: 0 30px !important;
  background-image: linear-gradient(263deg, var(--tealish), var(--blueberry)); }

.registration .male-button {
  position: inherit;
  padding: 0%;
  width: 45%; }

.registration .female-button {
  position: inherit;
  padding: 0%;
  width: 45%;
  margin: 3%; }

.registration .fruktbar-checker {
  color: var(--slate) !important;
  margin-bottom: 10px; }

.registration .inputfield-form {
  width: 375px;
  height: 56px;
  background-color: #ffffff;
  margin-top: 87px;
  margin-left: 42px;
  margin-right: 41px; }

.registration .inputfield-text {
  width: 214px;
  height: 22px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.5px;
  color: #000000; }

.registration .registration-text {
  font-size: 16px !important;
  color: var(--slate) !important; }

.registration .registration-text:nth-child(2) {
  z-index: 10; }

.registration .registration-text > div:nth-of-type(1) {
  border: none;
  border-bottom: 1px solid gray;
  border-radius: 0px;
  margin-top: 8px;
  height: 34px;
  min-height: 0px; }

.registration .registration-text > div > div:nth-of-type(1) {
  padding: 0px; }

.registration .registration-text > div > div > div:nth-of-type(1) {
  margin: 0px;
  color: #4b4f63; }

.registration label[for="animalBirthdate"] {
  background-color: white;
  color: #4b4f63;
  line-height: 18px;
  z-index: 1; }

.registration label[for="animalWeightDate"] {
  background-color: white;
  color: #4b4f63;
  line-height: 18px;
  z-index: 1; }

.registration div.weightRegistration > div {
  width: 50%; }

@media (max-width: 400px) {
  .registration div.weightRegistration > div {
    width: 100%; } }

.registration valueContainer {
  display: 'flex';
  flex-wrap: 'wrap';
  flex: 1;
  align-items: 'center';
  overflow: 'hidden';
  border: none !important;
  border-style: none !important;
  z-index: 2; }

.registration chip {
  margin: 0; }

.registration singleValue {
  font-size: 16; }

.registration placeholder {
  position: 'absolute';
  left: 2;
  bottom: 6;
  font-size: 16; }

.registration paper {
  position: 'absolute';
  z-index: 1;
  left: 0;
  right: 0; }

.registration .fancy-picture {
  padding: 10px;
  box-shadow: 4px 6px 6px #888888;
  transform: rotate(3deg) scale(0.8);
  -webkit-backface-visibility: hidden;
  width: 100%;
  height: calc(74vw);
  object-fit: cover;
  object-position: center center;
  max-height: 500px; }

.registration .dogpicture-content > div:nth-child(1) > ul:nth-child(4) > li:nth-child(1) {
  display: none; }

.registration .dogpicture-content > div:nth-child(1) > ul:nth-child(4) > li:nth-child(2) {
  display: none; }

.registration .dogpicture-content > div:nth-child(1) > ul:nth-child(4) > li:nth-child(3) {
  z-index: 10;
  text-align: center; }

.registration .dogpicture-content > div:nth-child(1) > ul:nth-child(4) > li:nth-child(3) > button:nth-child(1) {
  visibility: hidden;
  padding-top: 10px;
  z-index: 10; }

.registration .dogpicture-content > div:nth-child(1) > ul:nth-child(4) {
  list-style-type: none; }

.registration .dogpicture-content > div:nth-child(1) > ul:nth-child(4) > li:nth-child(3) > button:nth-child(1):after {
  content: "OK";
  cursor: pointer;
  visibility: visible;
  padding: 0 30px !important;
  background-color: #ffffff;
  width: 118.6px;
  height: 23.7px;
  border-radius: 3.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: 14px;
  line-height: 1.75;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border: solid 1px var(--blueberry);
  color: var(--blueberry); }

.registration .form-group {
  margin-top: 55px; }

.registration .notification-bar {
  bottom: 4rem !important;
  z-index: -100 !important;
  transition: all 0.5s cubic-bezier(0.89, 0.01, 1, 1.1) 0s !important; }

.registration .notification-bar-active {
  z-index: 100 !important; }

.modal-content {
  max-height: 100%;
  overflow-y: scroll; }
  .modal-content .gdpr-registration {
    background-color: white;
    padding: 2px 0px;
    margin-top: 40px; }
    @media (min-width: 600px) {
      .modal-content .gdpr-registration {
        width: 600px;
        margin: auto;
        margin-top: 80px; } }
    .modal-content .gdpr-registration .navbar {
      display: none; }
    .modal-content .gdpr-registration button.gdpr-modal-cross-button {
      float: right; }

.login {
  display: flex;
  flex-direction: column;
  margin-top: 37%;
  margin-left: 12%;
  margin-right: 12%; }
  .login .title {
    margin-bottom: 10%; }
  .login .underline::before {
    border-bottom: 1px solid #ff8b12 !important; }
  .login .underline {
    border-bottom: none !important; }
  .login .underline::after {
    border-bottom: none !important; }
  .login .input {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.25;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.5px;
    color: var(--blueberry); }
  .login .label {
    color: var(--slate); }
  .login .password-forgotten-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px; }
  .login .password-forgotten {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--blueberry);
    text-decoration: underline;
    cursor: pointer; }
  .login .button-container {
    display: grid;
    justify-content: center;
    margin-top: 20%; }
    .login .button-container .button {
      height: 45px;
      width: 180px;
      margin-top: 30px;
      border-radius: 35px;
      background-color: var(--tangerine) !important;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      text-transform: none; }

.landingpage {
  margin-bottom: 55px;
  background-color: #ffffff;
  /* NYHETSBANNER */
  /* common */
  /* top right*/ }
  .landingpage img.header {
    display: block;
    width: 100%; }
  .landingpage .title {
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: 22px; }
  .landingpage .heading {
    position: absolute;
    width: 100%;
    z-index: 1;
    height: 68px;
    background-color: rgba(31, 31, 103, 0.69); }
    .landingpage .heading .burger-container {
      float: right;
      padding: 20px; }
      @media (max-width: 360px) {
        .landingpage .heading .burger-container {
          padding: 20px 10px !important; } }
      .landingpage .heading .burger-container .loginbutton {
        border-radius: 3.5px;
        background-color: #ffffff;
        font-size: 10px;
        text-transform: inherit;
        padding: 8px 16px;
        margin-right: 10px; }
        .landingpage .heading .burger-container .loginbutton:hover {
          background-color: #d1d1d1; }
        .landingpage .heading .burger-container .loginbutton span {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: var(--blueberry); }
      .landingpage .heading .burger-container .menubutton {
        border-radius: 3.5px;
        background-color: #ffffff;
        padding: 8px 8px;
        height: 30px; }
        .landingpage .heading .burger-container .menubutton:hover {
          background-color: #d1d1d1; }
    .landingpage .heading .biotail-container {
      padding: 14px 26px; }
      @media (max-width: 360px) {
        .landingpage .heading .biotail-container {
          padding: 14px 8px !important; } }
  .landingpage .header {
    position: relative;
    margin-bottom: 80px;
    overflow: hidden;
    width: 100%; }
  .landingpage .headerImage {
    position: relative;
    margin-bottom: 0px;
    left: 50%;
    transform: translateX(-50%); }
  .landingpage .description {
    /*position: absolute;
        left: 6%;
        right: 6%;
        z-index: 20;
        bottom: 15px;*/
    z-index: 50;
    position: inherit;
    margin-bottom: 60px; }
    .landingpage .description .button-container {
      margin-top: 10px; }
  .landingpage .videopresentations-card {
    max-width: 800px;
    margin: auto;
    margin-bottom: 140px;
    padding: 26px; }
    .landingpage .videopresentations-card .info-card {
      max-width: 100%;
      height: auto; }
      .landingpage .videopresentations-card .info-card .info-card-header {
        justify-content: center;
        margin-bottom: 20px; }
      .landingpage .videopresentations-card .info-card .info-card-body {
        min-height: 110px; }
      .landingpage .videopresentations-card .info-card .info-card-body-link {
        bottom: 6px; }
  .landingpage .button-container {
    margin-top: -250px;
    margin-bottom: 150px; }
  .landingpage .info-text {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.5px;
    color: var(--blueberry);
    top: 50%; }
    @media (min-width: 600px) {
      .landingpage .info-text {
        font-size: 20px; } }
  .landingpage .button {
    position: absolute;
    max-width: 215px;
    height: 45px;
    padding: 0 30px;
    margin-top: -22px;
    font-weight: 700;
    border-radius: 35px;
    box-shadow: 4px 0 9px -1px rgba(0, 0, 0, 0.52);
    background-color: #6cefa1;
    z-index: 10;
    left: calc(50% - 107px);
    top: 640px;
    visibility: hidden; }
  .landingpage .kommer-image {
    padding-top: 20px;
    padding-bottom: 20px; }
  .landingpage .info-card-container-bg {
    background-color: rgba(56, 46, 146, 0.07); }
  .landingpage .info-card-container {
    max-width: 1200px;
    margin: auto;
    position: relative;
    top: -30px; }
  .landingpage .info-card {
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2);
    color: #382e92;
    margin-bottom: 21px; }
  .landingpage .info-card-content {
    position: relative;
    padding: 17px 17px 0 17px;
    width: 100%;
    background-color: #ffffff;
    z-index: 2;
    border-radius: 10px; }
  .landingpage .info-card-header {
    margin: auto;
    display: flex;
    padding-bottom: 10px;
    padding: 10px 0 0 10px; }
  .landingpage .info-card-image {
    float: left;
    margin-left: -11px;
    padding-right: 11px;
    margin-top: -10px; }
  .landingpage .info-card-text-header {
    margin-top: -11px;
    font-weight: bold;
    font-size: 26px; }
  .landingpage .info-card-body {
    margin-left: -10px;
    padding: 10px 10px 10px 10px; }
  .landingpage .info-card-body-text {
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif; }
  .landingpage .info-card-body-link {
    display: flex;
    text-decoration: underline;
    cursor: pointer;
    justify-content: flex-end;
    margin-right: -2px;
    font-size: 16px;
    margin-right: -30px; }
  .landingpage .button-link-text {
    margin-top: 10px;
    font-weight: normal;
    text-transform: lowercase;
    font-size: 16px; }
  .landingpage .button-link-text::first-letter {
    text-transform: uppercase; }
  .landingpage .info-card-body-link-image {
    margin-bottom: -16px;
    margin-right: 10px; }
  .landingpage .rectangle {
    height: 197px;
    background-color: rgba(56, 46, 146, 0.07);
    margin-bottom: 110px;
    margin-top: -280px; }
  .landingpage .circle {
    height: 18px;
    padding: 2px;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 9px;
    background-color: #ff8b12;
    font-size: 12px; }
  .landingpage .strek {
    height: 1px;
    background-color: #adafb8;
    margin-bottom: 25px;
    margin-top: 25px; }
  .landingpage .doggo-image-container {
    /*position: relative;
        justify-content: center;
        margin-top: -70px;
        width: 100%;
        overflow: hidden;*/
    background-image: url("/assets/images/biotail-footer.jpg");
    background-size: cover;
    background-position: center center;
    min-height: 300px;
    position: relative; }
  .landingpage .doggo-image-bottom {
    /*position: relative;
        left: 50%;
        transform: translateX(-50%);*/
    display: block;
    width: 100%; }
  .landingpage .survey-card {
    margin-right: auto;
    margin-left: auto;
    left: 0px;
    right: 0px;
    border-radius: 10px;
    max-width: 1150px;
    /* margin-top: -45px; */
    position: absolute;
    bottom: -45px;
    box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2);
    color: #382e92; }
  .landingpage .survey-card-content {
    position: relative;
    width: 100%;
    background-color: #ffffff;
    z-index: 2;
    border-radius: 10px; }
  .landingpage .survey-card-body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 24px;
    color: #382e92;
    font-weight: bold;
    display: flex;
    flex-wrap: nowrap;
    padding: 17px 17px 17px 17px; }
    .landingpage .survey-card-body .survey-card-title-container {
      display: flex;
      align-items: center; }
      .landingpage .survey-card-body .survey-card-title-container .survey-card-title {
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.2;
        letter-spacing: 0.5px;
        margin-top: 19px; }
    .landingpage .survey-card-body .survey-card-partners {
      width: 100%;
      justify-content: space-evenly;
      display: flex;
      align-items: center; }
  @supports (-ms-flow-from: thingy) {
    .landingpage {
      /* Edge only - Fix for partners logos */ }
      .landingpage .survey-card-body .survey-card-partners {
        justify-content: space-around; } }
  .landingpage .survey-image {
    margin-top: -10px;
    margin-right: 20px; }
  .landingpage .vipps-card {
    margin-right: 5%;
    margin-left: 5%;
    border-radius: 10px;
    box-shadow: 0 4px 20px 0px rgba(101, 101, 101, 0.18);
    color: #382e92;
    margin: auto;
    max-width: 760px;
    position: relative;
    top: -90px; }
    .landingpage .vipps-card .info-card-body-link {
      margin-right: -14px;
      margin-bottom: 4px; }
  .landingpage .survey-card-body.vipps {
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px; }
    .landingpage .survey-card-body.vipps hr {
      width: 100%;
      margin-top: 20px;
      background-color: var(--tangerine); }
    .landingpage .survey-card-body.vipps .survey-card-content-column {
      width: 50%;
      float: left;
      padding: 20px;
      padding-top: 40px; }
      .landingpage .survey-card-body.vipps .survey-card-content-column .survey-card-picture-resize {
        width: 300px;
        height: auto; }
      .landingpage .survey-card-body.vipps .survey-card-content-column:nth-child(2) {
        display: flex;
        height: 220px;
        align-items: center;
        justify-content: center; }
        .landingpage .survey-card-body.vipps .survey-card-content-column:nth-child(2) img {
          max-width: 100%; }
      .landingpage .survey-card-body.vipps .survey-card-content-column > :nth-child(1) {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--blueberry); }
      .landingpage .survey-card-body.vipps .survey-card-content-column .vippscontainer {
        position: relative;
        bottom: -46px;
        background-color: #FF5B24;
        display: flex;
        height: 53px;
        border-radius: 10px;
        padding: 10px;
        justify-content: space-around; }
        .landingpage .survey-card-body.vipps .survey-card-content-column .vippscontainer .vipps-number {
          font-size: 22px;
          color: white; }
      .landingpage .survey-card-body.vipps .survey-card-content-column .survey-card-content-baritem {
        margin-bottom: 14px; }
        .landingpage .survey-card-body.vipps .survey-card-content-column .survey-card-content-baritem p {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--blueberry); }
        .landingpage .survey-card-body.vipps .survey-card-content-column .survey-card-content-baritem div {
          height: 15px;
          border-radius: 0px;
          background-color: #e8e8e8; }
          .landingpage .survey-card-body.vipps .survey-card-content-column .survey-card-content-baritem div :not([role="progressbar"]) {
            background-color: #382e92; }
  .landingpage .oval {
    width: 113px;
    height: 112px;
    background-color: #f2f1f8;
    margin-top: -105px;
    margin-left: -25px;
    border-radius: 70px; }
  .landingpage .survey-text {
    margin-top: 20px;
    margin-right: 10px; }
  .landingpage .survey-card-green-bubble {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: #382e92;
    background-color: #6cefa1;
    border-radius: 35px;
    float: right;
    margin-left: auto;
    padding: 10px;
    height: 40px;
    margin-top: 15px;
    flex-shrink: 0; }
  .landingpage .ownertext {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-top: 40px; }
  .landingpage .bottom-text {
    margin-top: -4px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    color: #ffffff;
    background-color: #382e92;
    padding-bottom: 100px;
    padding-top: 60px; }
    .landingpage .bottom-text .contact {
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff; }
  .landingpage .collaborators {
    padding-top: 28px;
    margin-left: 34px;
    margin-right: 23px;
    display: flex; }
  .landingpage .collaborators-title {
    font-weight: bold; }
  .landingpage .b-image {
    float: right; }
  .landingpage .collaborators-list {
    text-decoration: underline; }
    .landingpage .collaborators-list ul {
      list-style-type: none; }
  .landingpage .strek2.hide-on-desktop {
    height: 1px;
    background-color: #ffffff;
    margin-bottom: 10px; }
  .landingpage .strek2 {
    height: 1px;
    background-color: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px; }
  .landingpage .contact {
    font-weight: 500;
    margin-left: 34px;
    margin-right: 23px; }
  .landingpage .developer-text {
    font-size: 14px;
    font-weight: normal; }
  .landingpage .link {
    color: white; }
  @media screen and (max-width: 360px) {
    .landingpage .info-card-text-header {
      font-size: 20px; } }
  @media screen and (max-width: 940px) {
    .landingpage .survey-card {
      position: unset;
      max-width: calc(100% - 40px); }
      .landingpage .survey-card .survey-card-body {
        flex-direction: column;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 30px; }
        .landingpage .survey-card .survey-card-body .survey-card-partners {
          flex-direction: column; }
          .landingpage .survey-card .survey-card-body .survey-card-partners img {
            margin-top: 40px; }
        .landingpage .survey-card .survey-card-body hr {
          width: 100%;
          margin-top: 20px;
          background-color: var(--tangerine); }
    .landingpage .description {
      margin-bottom: 0px; }
    .landingpage .header {
      margin-bottom: 10px; }
    .landingpage .vipps-card {
      margin-bottom: 40px;
      position: inherit;
      max-width: calc(100% - 10%);
      margin-top: -60px; }
      .landingpage .vipps-card .survey-card-content-container {
        width: 100%; }
      .landingpage .vipps-card .survey-card-content-column {
        width: 100% !important; }
        .landingpage .vipps-card .survey-card-content-column .vippscontainer {
          text-align: center;
          margin-bottom: 30px; }
          .landingpage .vipps-card .survey-card-content-column .vippscontainer img {
            max-width: 100%; }
    .landingpage .hide-on-mobile {
      display: none; }
    .landingpage .collaborators {
      flex-direction: column; }
      .landingpage .collaborators .strek2.hide-on-desktop {
        width: 100%; } }
  @media only screen and (min-width: 941px) {
    .landingpage .info-card-container {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center; }
    .landingpage .info-card {
      max-width: 348px;
      height: 216px;
      margin-left: 0;
      margin-right: 0; }
    .landingpage .first {
      margin-right: 1%;
      min-width: 307px; }
    .landingpage .center {
      min-width: 320px; }
    .landingpage .last {
      margin-left: 1%;
      min-width: 295px; }
    .landingpage .description {
      text-align: center;
      display: flex;
      justify-content: center; }
    .landingpage .info-text-container {
      max-width: 611px;
      display: block;
      justify-content: center; }
    .landingpage .hide-on-desktop {
      display: none; }
    .landingpage .info-card-container {
      min-height: 130px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-top: -252px;
      margin-bottom: 170px; }
    .landingpage .info-card-header {
      padding: 10px 0 0 10px; }
    .landingpage .info-card-body {
      min-height: 155px;
      display: flex;
      flex-direction: row;
      position: relative;
      padding: 10px 10px 10px 10px; }
    .landingpage .info-card-body-text {
      padding-bottom: 0; }
    .landingpage .info-card-body-link {
      margin-top: 10px;
      bottom: 0;
      right: 0;
      position: absolute;
      text-align: right;
      float: right;
      padding: 0 10px 0px 10px; }
    .landingpage .circle {
      bottom: 20px;
      right: 0;
      position: absolute;
      text-align: right;
      float: right; }
    .landingpage .info-card-body-link-text {
      bottom: 1%; }
    .landingpage .bottom-text {
      display: flex;
      flex-wrap: nowrap;
      margin: auto;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding-bottom: 50px; }
    .landingpage .collaborators {
      float: right;
      text-align: right;
      margin-right: 40px;
      flex-flow: column-reverse; }
    .landingpage .b-image {
      display: none; }
    .landingpage .contact {
      max-width: 300px;
      margin-top: 28px; }
    .landingpage .strek2 {
      margin-top: 15px;
      margin-bottom: 15px; } }
  @media only screen and (min-width: 1200px) {
    .landingpage .info-card {
      margin-left: auto;
      margin-right: auto; } }
  @media only screen and (min-width: 1440px) {
    .landingpage .headerImage {
      width: 100%; }
    .landingpage .doggo-image-bottom {
      width: 100%; } }
  .landingpage .doggo-image-container-withcontent {
    background-image: url(/assets/images/landingpage_dog.png);
    /* min-height: 500px; */
    height: 410px;
    padding-top: 150px;
    margin-bottom: 130px;
    background-size: cover;
    background-position: center center; }
    @media only screen and (max-width: 940px) {
      .landingpage .doggo-image-container-withcontent {
        min-height: 300px; } }
  .landingpage .info-card.wide {
    max-width: 1150px;
    height: auto;
    padding-bottom: 14px; }
    @media (max-width: 940px) {
      .landingpage .info-card.wide {
        margin-top: -200px; } }
    .landingpage .info-card.wide .info-card-header {
      float: left; }
    .landingpage .info-card.wide .info-card-body {
      min-height: unset;
      padding: 6px 20px; }
      @media only screen and (max-width: 940px) {
        .landingpage .info-card.wide .info-card-body {
          padding: 10px;
          padding-top: 38px; }
          .landingpage .info-card.wide .info-card-body img {
            margin-top: 20px; } }
  .landingpage .byline {
    font-weight: 400;
    font-size: 12px;
    color: #c5c5c5; }
  @media (max-width: 940px) {
    .landingpage .info-card.last {
      margin-bottom: 0px;
      position: relative;
      overflow: visible; }
      .landingpage .info-card.last .ribbon-top-right {
        right: -10px !important; }
    .landingpage .description .info-text-container {
      padding: 20px !important; }
    .landingpage .info-card-container {
      padding-bottom: 50px; }
    .landingpage .doggo-image-container {
      background-image: none;
      margin: auto;
      margin-top: 1240px; }
      .landingpage .doggo-image-container .survey-card {
        max-width: calc(100% - 10%);
        margin-bottom: -60px; }
    .landingpage .mobile-doggoimage {
      display: block !important;
      min-height: 300px;
      background-size: cover;
      background-position: center center;
      background-image: url("/assets/images/biotail-footer.jpg"); } }
  .landingpage .box {
    /*position: relative;
        max-width: 600px;
        width: 90%;
        height: 400px;
        background: #fff;
        box-shadow: 0 0 15px rgba(0,0,0,.1);*/ }
  .landingpage .ribbon {
    width: 128px;
    height: 128px;
    overflow: hidden;
    position: absolute; }
  .landingpage .ribbon::before,
  .landingpage .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #382e92; }
  .landingpage .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 10px 0;
    background-color: #4a3fa8;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center; }
  .landingpage .ribbon-top-right {
    top: -10px;
    right: 16px;
    z-index: 20; }
  .landingpage .ribbon-top-right::before,
  .landingpage .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent; }
  .landingpage .ribbon-top-right::before {
    top: 0;
    left: 0; }
  .landingpage .ribbon-top-right::after {
    bottom: 0;
    right: 0; }
  .landingpage .ribbon-top-right span {
    left: -35px;
    top: 32px;
    transform: rotate(45deg); }

.filter-expansion {
  box-shadow: none !important;
  color: #cbcbde !important; }

.filter-expansion::before {
  display: none; }

.filter-expansion .expansion-content {
  border-bottom: 1px solid #cbcbde !important; }

.filter-expansion-expanded {
  margin: 0 !important;
  border-bottom: none;
  color: #cbcbde !important; }

.filter-expansion .filter-expansion-title {
  font-weight: 500 !important;
  font-size: 15px !important;
  opacity: 1 !important; }

.filter-expansion-noBorderChange {
  box-shadow: none !important;
  color: #cbcbde !important;
  padding-bottom: 10px !important; }

.filter-expansion-noBorderChange::before {
  display: none; }

.filter-expansion-noBorderChange .expansion-content {
  border-bottom: 1px solid var(--tangerine) !important; }

.filter-expansion-expanded {
  margin: 0 !important;
  border-bottom: none;
  color: #cbcbde !important; }

.filter-expansion-noBorderChange .filter-expansion-title {
  font-weight: 500 !important;
  font-size: 15px !important;
  opacity: 1 !important; }

.expanded-font {
  font-size: 14px !important; }

.expansion-summary-expanded.expansion-content {
  border-bottom: 1px solid var(--tangerine) !important; }

.filter-expansion-expanded .expansion-summary-expanded.expansion-content {
  margin: 12px 0 !important; }

.filter-expansion-expanded .expansion-summary-expanded.expansion-summary-root {
  min-height: 48px !important; }

.gdpr-commonly {
  margin-top: 10%;
  margin-bottom: 60px; }
  .gdpr-commonly .title {
    margin-left: 6%;
    margin-right: 6%;
    margin-bottom: 8%; }
  .gdpr-commonly .divider {
    background-color: var(--tangerine);
    height: 1px;
    width: 100%; }
  .gdpr-commonly .text {
    font-size: 14px !important;
    color: var(--blueberry) !important;
    letter-spacing: 0.5px !important; }

.how-doggo-pic {
  display: block;
  width: 100%; }

.how {
  margin: 10% 12%;
  margin-bottom: 80px; }
  .how .divider {
    background-color: var(--tangerine);
    height: 1px;
    width: 100%;
    margin-bottom: 20px; }

.dots {
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.dots-container {
  display: flex;
  justify-content: space-between;
  width: 110px; }

.dot {
  width: 4px;
  height: 4px;
  background-color: var(--blueberry); }

.menu {
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  height: 100vh;
  overflow: scroll;
  padding-bottom: 160px;
  padding-top: 120px; }
  .menu .menu-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: white;
    width: 100%;
    height: 75vh;
    margin-bottom: 100px;
    margin-top: 160px;
    height: fit-content; }
  .menu .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    font-size: 20px;
    color: white !important;
    text-transform: none !important; }
  .menu .menu-item:hover {
    text-decoration: underline; }
  .menu .top-bar {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 2%;
    width: 100%;
    padding: 0 5%; }
    .menu .top-bar button {
      height: 42px;
      margin-top: 8px; }
    .menu .top-bar > a {
      width: 60px; }

.card-details {
  width: 97%;
  margin: 20px 0 !important;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2) !important;
  background-color: #ffffff; }

.card-actions {
  display: flex;
  justify-content: flex-end; }

.card-content-container {
  width: 100%; }

.card-content {
  padding: 0 !important; }

.card-actions {
  padding-top: 0 !important; }

.discount {
  cursor: pointer;
  padding: 0px 20px !important;
  padding-bottom: 20px !important; }

.button-details-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 5%;
  width: 100%; }

.dog-details-subheader {
  margin: 0% 0% 0% 0%; }

.dog-details-wrapper {
  margin: 3% 0% 2% 0%; }

.MuiTypography-body1.dog-details-questionnaires-title {
  font-size: 20px;
  margin-left: 20px;
  border-bottom: 1px solid #ff8b12;
  width: calc(100% - 48px); }

.dog-details-button {
  height: 26px;
  border-radius: 4px;
  font-size: 12px !important;
  color: white !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.28);
  background-image: linear-gradient(257deg, #2ec3cd, var(--blueberry)); }

.disabled {
  color: #afacac !important; }

@media only screen and (max-width: 350px) {
  .dog-details-button {
    font-size: 11px !important; } }

.feature-button-small {
  padding: 10px;
  margin-bottom: 20px;
  background-color: #f2f2f2;
  border-radius: 6px;
  text-align: left; }
  .feature-button-small img {
    margin-bottom: 20px;
    margin-right: 16px;
    margin-left: 6px;
    margin-top: 5px;
    height: 24px;
    float: left; }
  .feature-button-small h1 {
    color: #382e92;
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.00938em;
    margin-bottom: 6px; }
  .feature-button-small p {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.5px;
    font-size: 12px;
    color: #464646;
    margin-bottom: 0px; }

.feature-button-small:hover {
  background-color: #e4e4e4;
  box-shadow: 0px 0px 8px 0px #e2e2e2;
  cursor: pointer; }

.feature-button-large {
  padding: 30px;
  margin-bottom: 30px;
  background-color: #f2f2f2;
  border-radius: 6px;
  text-align: center;
  height: 180px; }
  .feature-button-large .highlightcontainer {
    position: absolute;
    top: 5px;
    right: 22px; }
    .feature-button-large .highlightcontainer span {
      font-size: 40px;
      color: #ff8b12; }
  .feature-button-large .icon {
    font-size: 42px;
    margin-bottom: 20px;
    color: #6cefa1; }
  .feature-button-large img {
    margin-bottom: 20px; }
  .feature-button-large h1 {
    color: #382e92;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 700;
    line-height: 0.75;
    letter-spacing: 0.00938em; }
  .feature-button-large p {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.5px;
    font-size: 14px;
    color: #464646;
    margin-bottom: 0px; }

.feature-button-large:hover {
  background-color: #e4e4e4;
  box-shadow: 0px 0px 8px 0px #e2e2e2;
  cursor: pointer; }

.feature-button-container {
  padding-right: 20px; }

.dog-details-container {
  display: flex;
  flex-direction: column;
  width: 100%; }
  .dog-details-container .dog-details-row {
    display: flex;
    justify-content: space-between; }
  .dog-details-container .dog-details-row:nth-child(2n) {
    background-color: #f2f2f2; }
  .dog-details-container .dog-details-title {
    color: black !important;
    font-size: 16px !important; }

.bottom-nav {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  width: 100%; }

.nav-icon {
  min-width: 0 !important;
  width: 20px !important; }

.nav-icon-selected {
  border-bottom: 3px solid var(--tangerine) !important; }

@media (min-width: 800px) {
  .bottom-nav {
    display: none; } }

.my-dogs .loading-text {
  padding: 80px 0px;
  text-align: center; }

.my-dogs .my-dogs-title {
  top: 4%;
  align-items: center;
  margin-top: -50px;
  z-index: 9;
  text-align: center; }

.my-dogs .more-dogs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: 100%;
  color: white !important;
  flex-direction: row;
  margin: 0;
  border-radius: 0 !important;
  margin-top: 10vh;
  padding: 0 24%;
  text-align: center;
  text-align: center;
  line-height: 16px;
  background-color: var(--blueberry) !important; }

@media only screen and (min-width: 375px) {
  .my-dogs .more-dogs {
    padding: 0 28%; } }

.my-dogs .more-dogs-text {
  font-size: 16px;
  font-weight: bold;
  color: white !important;
  line-height: 2.5 !important; }

.my-dogs .button-container {
  width: 100%;
  text-align: center; }

.my-dogs .add-button {
  margin-top: 12px;
  padding: 0 30px !important;
  height: 40px;
  border-radius: 35px;
  background-image: linear-gradient(263deg, var(--tealish), var(--blueberry)); }

.thanks-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 43vh; }

.thanks-card {
  position: absolute;
  width: 300px;
  top: 8%;
  height: 200px !important;
  background-color: white !important;
  padding: 0 !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2);
  z-index: 10; }

.thanks-card-content {
  padding: 0 !important; }
  .thanks-card-content img {
    width: 100%;
    border-radius: 10px !important; }

.card-thanks-shadow {
  position: absolute;
  top: 10%;
  width: 287px;
  height: 200px;
  border-radius: 10px !important;
  box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2) !important;
  background-color: white !important; }

.card-thanks-title-container {
  position: relative;
  width: 100%; }

.card-thanks-title {
  position: absolute;
  top: 8%;
  left: 3%; }

.redeem-button {
  width: 63px;
  height: 26px;
  font-size: 12px !important;
  color: white !important;
  margin-top: -3% !important;
  text-transform: none !important;
  padding-top: 4px !important;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.28);
  background-image: linear-gradient(253deg, #2ec2cd, var(--blueberry)); }

.rabatt-code-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8%;
  margin-left: 3%;
  margin-right: 5%; }

.thanks-description-container {
  margin: 0 10%; }

.go-button {
  width: 162px;
  height: 40px;
  border-radius: 35px !important;
  background-image: linear-gradient(258deg, var(--tealish), var(--blueberry));
  color: white !important;
  text-transform: none !important; }

.go-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 3%; }

@media screen and (min-width: 375px) {
  .thanks-container {
    height: 45vh; }
  .thanks-card {
    height: 220px; }
  .rabatt-code-container {
    margin-top: 8%; }
  .card-thanks-title {
    left: 6%; }
  .go-button-container {
    margin-top: 10%; } }

@media screen and (min-width: 410px) {
  .thanks-card {
    height: 216px; }
  .thanks-card {
    height: 230px; }
  .rabatt-code-container {
    margin-top: 4%; }
  .card-thanks-title {
    left: 8%; } }

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .thanks-container {
    height: 40vh; }
  .go-button-container {
    margin-top: 20%; } }

.slider-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }
  .slider-container .dog-details-row {
    display: flex;
    justify-content: space-between; }
  .slider-container .dog-details-row:nth-child(2n) {
    background-color: #f2f2f2; }
  .slider-container .dog-details-title {
    color: black !important;
    font-size: 16px !important; }

.slick-slider .slick-next {
  right: 32px; }

.slick-slider .slick-arrow {
  z-index: 1000; }
  .slick-slider .slick-arrow::before {
    color: black; }

.slick-slider .slick-list {
  overflow: inherit; }

.slider-image-container {
  position: relative; }

.slider-image-container img {
  width: calc(100% - 20px);
  height: auto;
  border-radius: 10px;
  box-shadow: 3px 5px 5px #ccc; }

.slick-dots {
  bottom: -45px !important; }

.slick-dots li button:focus:before {
  opacity: 0.25 !important; }

.slick-dots li.slick-active button:focus:before, .slick-dots li button:hover:before {
  opacity: 1 !important; }

.slider-button {
  font-size: 11px !important;
  color: white !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.28);
  background-image: linear-gradient(257deg, #2ec3cd, var(--blueberry)); }

.slider-button.selected {
  background-image: linear-gradient(257deg, #29c30d, #2e9294); }

.slider-button-container {
  position: relative;
  bottom: 40px;
  left: 10px; }

.slider-addbutton-container {
  position: absolute;
  right: 20px;
  bottom: 16px; }

.slider-removebutton-container {
  position: absolute;
  top: -20px;
  right: 10px; }

.slider-removebutton-container button {
  background-color: #b51e1e; }

.slider-removebutton-container button:hover {
  background-color: #b51e1e; }

.summary {
  margin-bottom: 80px; }
  .summary .summary-card {
    padding-top: 10px;
    margin-right: 4%;
    margin-top: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2); }
  .summary .summary-card-title {
    padding-left: 5%;
    padding-right: 2%; }
  .summary .container-dots {
    margin-bottom: 30px; }
  .summary .button-container {
    display: flex;
    justify-content: center;
    align-items: center; }
  .summary .text {
    font-size: 14px !important;
    color: var(--blueberry) !important; }
  .summary .slider-container {
    max-width: 322px;
    padding-left: 0px;
    padding-right: 3px;
    max-height: 200px; }
  .summary .button-summary {
    height: 45px;
    padding: 0 5%;
    margin-top: 50px;
    margin-bottom: 30px;
    font-weight: 700;
    border-radius: 35px;
    box-shadow: 4px 0 9px -1px rgba(0, 0, 0, 0.52);
    background-color: #6cefa1 !important;
    z-index: 10; }

.input-registration-text {
  font-size: 16px !important;
  color: var(--slate) !important; }

.my-dog {
  margin-bottom: 80px; }
  .my-dog .fieldlabel {
    margin: 0px;
    margin-top: 20px;
    color: #382e92;
    padding: 0;
    font-size: 10px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.5px; }
  .my-dog .savebtn {
    float: right; }
  .my-dog .loading-text {
    padding: 80px 0px;
    text-align: center; }
  .my-dog input[disabled] {
    color: rgba(0, 0, 0, 0.38); }
  .my-dog .my-dog-title {
    top: 4%;
    align-items: center;
    margin-top: -50px;
    z-index: 9;
    margin-bottom: 20px;
    text-align: center; }
  .my-dog .my-dog-card {
    margin-right: 5%;
    border-radius: 10px;
    box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2); }
  .my-dog .text {
    font-size: 14px !important;
    color: var(--blueberry) !important; }
  .my-dog .slider-container {
    max-width: 266px;
    padding-left: 0px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 3px; }
  .my-dog .dog-features-form {
    position: relative;
    padding: 7% 0% 7% 8%;
    width: 100%;
    background-color: #ffffff; }
  @media only screen and (min-width: 375px) {
    .my-dog .slider-container {
      max-width: 322px; } }
  .my-dog .name-container {
    display: flex;
    justify-content: space-between;
    margin-top: 40px; }
  .my-dog .name-container .button-likes {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 28px;
    border-radius: 17.5px;
    border: solid 2px var(--tangerine);
    font-size: 12px;
    line-height: 12px;
    color: var(--blueberry);
    font-weight: bold;
    padding: 0 !important; }
  .my-dog .edit-button {
    color: white;
    text-align: center;
    width: 64px;
    font-size: 12px;
    text-transform: none !important;
    height: 26px;
    line-height: 12px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.28);
    background-image: linear-gradient(253deg, #2ec2cd, var(--blueberry)); }
  .my-dog .delete-button {
    color: white;
    text-align: center;
    width: 64px;
    font-size: 12px;
    text-transform: none !important;
    height: 26px;
    line-height: 12px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.28);
    background-image: linear-gradient(253deg, #e43939, #802121);
    margin-right: 20px; }
  .my-dog .button-container {
    width: 100%;
    text-align: center !important;
    margin-top: 20px; }
  .my-dog .registerexterior-button {
    color: white;
    text-align: center;
    padding: 0 30px !important;
    width: 100%;
    font-size: 14px;
    text-transform: none !important;
    height: 34px;
    line-height: 12px;
    border-radius: 4px;
    margin-top: 10px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.28);
    background-image: linear-gradient(253deg, #2ec2cd, var(--blueberry)); }
  .my-dog .edit-text-fields {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px; }
  .my-dog .text {
    color: #515568;
    font-size: 14px;
    letter-spacing: 0.5px; }
  .my-dog .edit-text {
    width: 100%;
    font-size: 16px !important;
    color: var(--slate) !important;
    border-bottom-color: black !important; }
  .my-dog .disabled.registration-text > div > div > div {
    color: #BDBDBD; }
  .my-dog .registration-text > div > div > div {
    color: #382E92; }
  .my-dog .certificate-shadow-card {
    border-radius: 10px;
    box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    margin: auto;
    padding-bottom: 10px;
    left: 0px;
    max-width: 351px; }
  .my-dog .certificate-shadow-card-mobile {
    border-radius: 10px;
    box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2);
    width: calc(100% - 22px);
    height: 30px;
    position: absolute;
    bottom: -10px;
    background-color: white;
    left: 11px;
    z-index: -10;
    display: block !important; }
  .my-dog .certificate-card {
    padding-bottom: 20px;
    margin: 0 auto;
    top: -4% !important;
    top: 8%;
    background-color: white !important;
    padding: 0 !important;
    z-index: 10;
    width: calc(100% + 22px);
    left: -11px !important;
    border-radius: 10px !important;
    box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2) !important;
    max-width: 373px;
    position: relative; }
    .my-dog .certificate-card .certificate-card-image {
      width: 100%; }
  .my-dog .certificate-card-content {
    padding: 0 !important; }
    .my-dog .certificate-card-content img.background {
      width: 100%;
      border-radius: 10px !important; }
  .my-dog .card-certificate-title-container {
    position: relative;
    width: 100%; }
    .my-dog .card-certificate-title-container img {
      filter: drop-shadow(0px 20px 10px rgba(0, 0, 0, 0.1)); }
  .my-dog .card-certificate-title {
    position: absolute;
    top: 4%;
    left: 5%;
    z-index: 10; }
  .my-dog .card-certificate-title p {
    margin-top: 6px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.5px; }
  .my-dog .certificate-image {
    width: 100%; }
  .my-dog .certificate-bottom-container {
    padding-left: 17px;
    padding-bottom: 14px;
    padding-right: 14px;
    padding-top: 14px;
    overflow: hidden; }
    .my-dog .certificate-bottom-container p {
      float: left;
      margin-top: 6px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: 0.5px;
      color: var(--blueberry);
      clear: both; }
    .my-dog .certificate-bottom-container .edit-button {
      float: right;
      color: white;
      text-align: center;
      width: 64px;
      font-size: 12px;
      text-transform: none !important;
      height: 26px;
      line-height: 12px;
      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.28);
      background-image: linear-gradient(253deg, #2ec2cd, var(--blueberry)); }
  @media (max-width: 400px) {
    .my-dog .certificate-card {
      width: calc(100% - 20px);
      margin-left: 20px; }
    .my-dog .certificate-shadow-card {
      width: calc(100% - 50px);
      height: auto; }
    .my-dog .certificate-shadow-card-mobile {
      display: block; } }
  .my-dog .dog-gender-header {
    margin-top: 5%;
    color: var(--slate); }
  .my-dog .dog-gender-container {
    margin-bottom: 10;
    bottom: 10%;
    color: var(--slate); }
  .my-dog .male-button {
    position: inherit;
    padding: 0%;
    width: 45%; }
  .my-dog .female-button {
    position: inherit;
    padding: 0%;
    width: 45%;
    margin: 3%; }
  .my-dog .dog-birthdate-picker {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px; }
    .my-dog .dog-birthdate-picker > div:first-child {
      width: 100%; }
  .my-dog .dog-race-picker {
    margin-top: 16px; }
    .my-dog .dog-race-picker > p {
      margin: 0px;
      margin-bottom: -2px;
      font-size: 14px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.5px;
      transform: translate(0, 1.5px) scale(0.75);
      transform-origin: top left; }
  .my-dog .dog-sex-picker > p {
    margin: 0px;
    margin-bottom: -2px;
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.5px;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left; }
  .my-dog .dog-sex-dropdown {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.1876em;
    letter-spacing: 0.5px;
    font-size: 14px; }
    .my-dog .dog-sex-dropdown > div {
      background-color: white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      border-bottom-style: dotted;
      border-radius: 0px; }
  .my-dog .button-container-newregisters {
    text-align: center; }
  .my-dog .earlier-registrations {
    margin-top: 30px; }
    .my-dog .earlier-registrations .earlier-registrations-header {
      padding: 23px 0px 13px 0px;
      text-align: center;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: 0.5px;
      text-align: center;
      color: #ffffff;
      background-color: var(--blueberry); }
    .my-dog .earlier-registrations .earlier-registrations-item {
      height: auto;
      width: calc(100% - 30px);
      margin: auto;
      margin-top: 14px;
      box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2) !important;
      border-radius: 10px;
      overflow: hidden;
      overflow: visible;
      height: 184px;
      margin-top: 30px;
      margin-bottom: 46px; }
      .my-dog .earlier-registrations .earlier-registrations-item button {
        padding: 0px 10px;
        width: max-content;
        height: 26px;
        border-radius: 4px;
        background-color: var(--blueberry);
        border: none;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        position: relative;
        bottom: -10px;
        left: 0px; }
      .my-dog .earlier-registrations .earlier-registrations-item > div {
        float: left;
        padding: 20px;
        width: 50%; }
      .my-dog .earlier-registrations .earlier-registrations-item img {
        float: left;
        width: 50%;
        height: 100%; }
      .my-dog .earlier-registrations .earlier-registrations-item .earlier-registrations-item-title {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: 0.5px;
        color: var(--blueberry);
        margin-bottom: 0px; }
      .my-dog .earlier-registrations .earlier-registrations-item .earlier-registrations-item-text {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: 0.5px;
        color: var(--blueberry); }
      .my-dog .earlier-registrations .earlier-registrations-item .mini-certificate-wrapper {
        position: relative;
        padding: 0;
        box-shadow: 0px 0px 10px 0px #a5a5a5;
        height: 200px;
        top: -8px;
        border-radius: 10px;
        background-color: white;
        padding-bottom: 30px; }
        .my-dog .earlier-registrations .earlier-registrations-item .mini-certificate-wrapper .mini-certificate-title {
          position: absolute;
          left: 20px;
          top: 12px;
          z-index: 1;
          color: white;
          font-weight: 400;
          font-size: 20px; }
        .my-dog .earlier-registrations .earlier-registrations-item .mini-certificate-wrapper .mini-certificate-background {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: bottom;
          border-radius: 10px; }
        .my-dog .earlier-registrations .earlier-registrations-item .mini-certificate-wrapper .mini-certificate-image {
          position: absolute;
          border-radius: 50%;
          /* left: 63%; */
          right: 20px;
          width: 120px;
          height: 120px;
          bottom: 5%;
          /* box-shadow: 2px 2px 9px 0px rgb(0 0 0 / 31%); */ }
  .my-dog .weightRegistrations {
    margin-top: 40px; }
    .my-dog .weightRegistrations h6 {
      font-size: 1.25rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 0.0075em; }
    .my-dog .weightRegistrations table {
      width: 100%; }
      .my-dog .weightRegistrations table th {
        font-weight: 500; }
    .my-dog .weightRegistrations .dateInput .MuiInputBase-root {
      margin-bottom: 8px; }
  .my-dog .regnumberRegistrations {
    margin-top: 40px; }
    .my-dog .regnumberRegistrations h6 {
      font-size: 1.25rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 0.0075em; }
    .my-dog .regnumberRegistrations > label {
      float: right; }
    .my-dog .regnumberRegistrations > div {
      margin-top: 16px; }
  .my-dog .slick-dots {
    bottom: -20px !important; }
  .my-dog .slick-dots li button:focus:before {
    opacity: 0.25; }
  .my-dog .slick-dots li.slick-active button:focus:before, .my-dog .slick-dots li button:hover:before {
    opacity: 1; }
  .my-dog .slider-image-container img {
    object-fit: cover;
    object-position: center center;
    height: 60vw; }
  .my-dog .image-edit-container {
    width: 100%;
    text-align: center; }
  .my-dog .image-edit-container > div:nth-child(1) > ul:nth-child(4) > li:nth-child(1) {
    display: none; }
  .my-dog .image-edit-container > div:nth-child(1) > ul:nth-child(4) > li:nth-child(2) {
    display: none; }
  .my-dog .image-edit-container > div:nth-child(1) > ul:nth-child(4) > li:nth-child(3) {
    z-index: 10;
    text-align: center; }
  .my-dog .image-edit-container > div:nth-child(1) > ul:nth-child(4) > li:nth-child(3) > button:nth-child(1) {
    visibility: hidden;
    z-index: 10; }
  .my-dog .image-edit-container > div:nth-child(1) > ul:nth-child(4) {
    list-style-type: none; }
  .my-dog .image-edit-container > div:nth-child(1) > ul:nth-child(4) > li:nth-child(3) > button:nth-child(1):after {
    content: "OK";
    cursor: pointer;
    visibility: visible;
    padding: 0 30px !important;
    background-color: #ffffff;
    width: 118.6px;
    height: 23.7px;
    border-radius: 3.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-size: 14px;
    line-height: 1.75;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    border: solid 1px var(--blueberry);
    color: var(--blueberry); }
  .my-dog .form-group {
    margin-top: 45px; }
  .my-dog .notification-bar {
    left: -117% !important;
    bottom: 4rem !important;
    z-index: -100 !important;
    transition: all 0.5s cubic-bezier(0.89, 0.01, 1, 1.1) 0s !important; }
  .my-dog .notification-bar-active {
    left: 0% !important;
    z-index: 100 !important; }
  .my-dog .edit-container {
    height: 18px; }
    .my-dog .edit-container .deletebtn {
      float: left; }
      .my-dog .edit-container .deletebtn button {
        width: 155px; }
    .my-dog .edit-container .savebtn {
      float: right; }

.dog-feature-modal-cross-button {
  position: sticky !important;
  left: 90%;
  top: 15px; }
  .dog-feature-modal-cross-button svg {
    background-color: white;
    border-radius: 4px; }

.dog-feature-modal-content {
  max-height: 100%;
  overflow-y: scroll; }

.feature-card-moda {
  overflow-y: scroll; }

.proportionsfeaturecontainer .slider-image-container img {
  max-height: unset !important; }

.dog-features-form {
  position: relative;
  padding: 7% 0% 7% 8%;
  width: 100%;
  background-color: #ffffff; }

.feature-card {
  padding: 0px 0px 20px; }

.slider-button-container {
  position: absolute;
  right: 16%;
  bottom: 3%; }

.slider-image-container img {
  max-height: 250px; }

.dog-features-title {
  font-size: 14px !important;
  font-weight: bold !important;
  letter-spacing: 1.5px !important;
  color: var(--blueberry) !important; }

.picture-button-container {
  position: relative; }

.camera-button-text {
  position: absolute;
  width: 10em;
  bottom: -5%; }

.register-button-wrapper {
  margin: 2% 0% 5% 0%; }

.dog-wizard-slider-wrapper {
  padding: 5% 10% 0% 10%; }

.measuring-content-wrapper {
  text-align: center;
  padding: 5%; }

.certificate-shadow-card {
  border-radius: 10px;
  box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2);
  position: relative;
  margin: auto;
  padding-bottom: 10px;
  left: 0px;
  max-width: 351px; }

.certificate-shadow-card-mobile {
  border-radius: 10px;
  box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2);
  width: calc(100% - 22px);
  height: 30px;
  position: absolute;
  bottom: -10px;
  background-color: white;
  left: 11px;
  z-index: -10;
  display: none; }

.certificate-card {
  margin: 0 auto;
  top: -4% !important;
  top: 8%;
  background-color: white !important;
  padding: 0 !important;
  z-index: 10;
  width: calc(100% + 22px);
  left: -11px !important;
  border-radius: 13px !important;
  box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2) !important;
  max-width: 373px;
  position: relative; }
  .certificate-card .certificate-card-image {
    width: 100%; }

.certificate-card-content {
  padding: 0 !important; }
  .certificate-card-content img.background {
    width: 100%;
    border-radius: 13px !important; }

.card-certificate-title-container {
  position: relative;
  width: 100%; }
  .card-certificate-title-container img {
    filter: drop-shadow(0px 20px 10px rgba(219, 178, 178, 0.1)); }

.card-certificate-title {
  position: absolute;
  top: 4%;
  left: 5%;
  z-index: 10; }

.card-certificate-title p {
  margin-top: 6px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.5px; }

.certificate-image-container {
  position: absolute;
  width: 110px;
  right: 35px;
  top: 46px; }

.certificate-image {
  position: absolute;
  width: 103px;
  height: 103px;
  border-radius: 50%;
  bottom: 57px;
  left: 10px;
  box-shadow: 0px 2px 5px 0px #00000082; }

.certificate-bottom-container {
  padding-left: 17px;
  padding-bottom: 14px;
  padding-right: 14px;
  padding-top: 14px;
  overflow: hidden; }
  .certificate-bottom-container p {
    float: left;
    margin-top: 6px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.5px;
    color: var(--blueberry); }
  .certificate-bottom-container .edit-button {
    float: right;
    color: white;
    text-align: center;
    width: 64px;
    font-size: 12px;
    text-transform: none !important;
    height: 26px;
    line-height: 12px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.28);
    background-color: gray; }

@media (max-width: 400px) {
  .certificate-card {
    width: calc(100%); } }

.wizfinished-text-container {
  margin-top: 45px;
  padding: 42px; }

.wizfinished-text-container .divider {
  margin-bottom: 8px; }

.button-placement > button {
  display: block;
  margin: auto; }

.dog-features-img-container {
  position: relative;
  background: #d8d8d8;
  margin-left: 15px;
  width: calc(100% - 30px); }
  .dog-features-img-container > img {
    display: none; }
  .dog-features-img-container > img[src] {
    display: block; }

.dog-features-stepper {
  margin-bottom: 50px; }

.breedwiz-loading-text {
  text-align: center;
  margin-top: 40px !important; }

.stepper {
  width: 100%;
  max-width: 800px;
  background: white;
  bottom: 50px; }

.button-container {
  width: 100%;
  text-align: center; }

.add-button {
  margin-top: 12px;
  padding: 0 30px !important;
  height: 40px;
  border-radius: 35px;
  background-image: linear-gradient(263deg, var(--tealish), var(--blueberry)); }

.add-button.start-registration-button {
  margin-bottom: 30px;
  margin-top: 60px; }

.dialog-content {
  margin: 5%;
  position: relative; }

.cross-button {
  position: absolute !important;
  right: -6%;
  top: -11%; }

.info-button {
  left: 45%; }

@media (max-width: 330px) {
  .info-button {
    left: 40%; }
  .cross-button {
    position: absolute !important;
    right: -7%;
    top: -9%; } }

.text-wrapper {
  margin: 10% 12%; }
  .text-wrapper .divider {
    background-color: var(--tangerine);
    height: 1px;
    width: 100%;
    margin-bottom: 20px; }

.gallery-container {
  padding: 40px;
  margin-bottom: 200px; }
  .gallery-container .divider {
    margin-bottom: 4px; }

.gallery-header .logo {
  float: left;
  cursor: pointer;
  width: 60px;
  height: 60px;
  margin-top: 6px; }

.gallery-header .icon-button {
  float: right;
  height: 49px;
  margin-top: 20px; }

.gallery-header .breed-dropdown:first-child:first-child {
  background-color: transparent;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.75;
  letter-spacing: normal;
  color: var(--slate);
  cursor: pointer; }

.gallery-header .breed-dropdown > div:nth-of-type(1) {
  background-color: transparent;
  cursor: pointer; }

.gallery-header .breed-dropdown {
  background-color: transparent;
  z-index: 10;
  cursor: pointer; }

.gallery-header .breed-dropdown-text {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--slate);
  position: relative;
  top: 10px;
  z-index: -10; }

.gallery-header .gallery-select-breed {
  margin: 0px 20px;
  padding-top: 6px;
  width: 300px;
  float: left;
  cursor: pointer; }

.gallery-header .gallery-select-sorting {
  width: 100%;
  margin-top: 20px; }

.gallery-header .gallery-select-sorting button {
  opacity: 0.35;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #818c9e;
  background: none;
  border: none;
  margin-left: 20px; }

.gallery-header .gallery-select-sorting button {
  opacity: 0.35;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #818c9e;
  background: none;
  border: none;
  cursor: pointer; }

.gallery-header .gallery-select-sorting button:focus {
  border: none;
  outline: none; }

.gallery-header .gallery-select-sorting button.selected {
  color: var(--blueberry);
  opacity: 1;
  border-bottom: solid 2px var(--tangerine) !important; }

.gallery-stepper-container {
  display: none;
  position: fixed;
  bottom: 0px; }
  .gallery-stepper-container > div {
    margin-bottom: 0px;
    max-width: 100%; }

.gallery-reapeatbtn {
  position: fixed;
  bottom: 66px;
  right: 20px; }
  .gallery-reapeatbtn button {
    background-color: var(--blueberry);
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.2), 0px 0px 10px 0px rgba(0, 0, 0, 0.14), 0px 0px 18px 0px rgba(0, 0, 0, 0.12); }
  .gallery-reapeatbtn button.enabled {
    background-color: #279e24;
    animation: spin 6s linear infinite;
    -webkit-animation: spin 6s linear infinite;
    -moz-animation: spin 6s linear infinite; }
  .gallery-reapeatbtn button.disabled {
    background-color: grey; }
  @media (max-width: 800px) {
    .gallery-reapeatbtn > :nth-child(1) {
      display: none; } }

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@media (max-width: 1110px) {
  .gallery-header .gallery-select-breed {
    float: none;
    clear: both; }
  .gallery-header .gallery-select-sorting {
    float: none;
    clear: both;
    text-align: left; }
  .gallery-header .icon-button {
    float: right;
    position: absolute;
    right: 10px; } }

@media (min-width: 1111px) {
  .gallery-header {
    position: fixed;
    background-color: white;
    width: 100%;
    top: 0px;
    left: 0px;
    box-shadow: 0px 0px 16px #00000073;
    justify-content: center; }
    .gallery-header .breed-selection {
      display: flex;
      justify-content: center;
      align-items: center;
      top: 13px;
      left: 110px;
      position: absolute; }
      .gallery-header .breed-selection .gallery-select-sorting {
        align-self: center; }
    .gallery-header .gallery-select-breed {
      clear: both; }
  .gallery-container {
    margin-top: 100px; } }

@media (max-width: 400px) {
  .gallery-container {
    padding: 60px 20px; }
    .gallery-container .li > div :nth-child(1) {
      width: 270px; }
    .gallery-container .li > div :nth-child(2) {
      width: 260px; } }

@media (max-width: 355px) {
  .gallery-container li > div :nth-child(1) {
    width: 270px !important; }
  .gallery-container li > div :nth-child(2) {
    width: 260px !important; } }

.feature-modal-content {
  padding-top: 40px;
  margin: 20px; }
  .feature-modal-content .certificate-bottom-container p {
    float: none; }
  .feature-modal-content .summary-slider-line {
    margin-top: '15%';
    color: '#52af77'; }
  .feature-modal-content .summary-slider-thumb {
    color: '#52af77' !important; }
  .feature-modal-content .MuiAccordionActions-root {
    padding: 17px; }

.admin {
  max-width: 1000px;
  margin: auto;
  padding: 40px; }
  .admin hr {
    background-color: var(--tangerine); }
  .admin .imageapproval {
    margin-bottom: 30px; }
    .admin .imageapproval .itemcontainer {
      width: calc(24% - 28px);
      float: left;
      margin: 15px;
      box-shadow: 0px 0px 9px #dedede;
      padding: 10px;
      border-radius: 8px; }
      .admin .imageapproval .itemcontainer > div {
        display: flex;
        justify-content: space-between; }
        .admin .imageapproval .itemcontainer > div .acceptbtn {
          background-color: #2bb919;
          color: white;
          padding: 6px; }
        .admin .imageapproval .itemcontainer > div .rejectbtn {
          background-color: #ca1c1c;
          color: white;
          padding: 6px; }
      .admin .imageapproval .itemcontainer img {
        width: 100%;
        object-fit: contain;
        object-position: center center;
        height: 150px;
        margin-bottom: 10px; }
      @media (max-width: 1000px) {
        .admin .imageapproval .itemcontainer {
          width: calc(50% - 35px); }
          .admin .imageapproval .itemcontainer > div {
            justify-content: space-around;
            flex-direction: column;
            height: 150px;
            float: right; }
          .admin .imageapproval .itemcontainer img {
            width: auto;
            float: left;
            margin: 0px;
            margin-right: 10px; } }
      @media (max-width: 760px) {
        .admin .imageapproval .itemcontainer {
          width: calc(100% - 30px); }
          .admin .imageapproval .itemcontainer > div {
            justify-content: space-around;
            flex-direction: column;
            height: 150px;
            float: right; }
          .admin .imageapproval .itemcontainer img {
            width: auto;
            float: left;
            margin: 0px;
            margin-right: 10px; } }
      @media (max-width: 445px) {
        .admin .imageapproval .itemcontainer {
          width: calc(100% - 30px); }
          .admin .imageapproval .itemcontainer > div {
            flex-direction: row;
            height: auto;
            float: left;
            width: 100%;
            margin-top: 10px; }
          .admin .imageapproval .itemcontainer img {
            width: 100%; } }
  .admin .MuiAccordionDetails-root {
    display: block; }
    .admin .MuiAccordionDetails-root > div {
      padding-top: 20px; }
    .admin .MuiAccordionDetails-root .col > div {
      box-shadow: 0px 2px 4px 0px #00000054;
      border-radius: 4px;
      padding: 12px;
      overflow: hidden; }

.statistics .header {
  margin-bottom: 0px;
  height: 400px; }
  .statistics .header img.headerImage {
    margin-bottom: 0px; }

@media (min-width: 800px) {
  .statistics .phoneonly {
    display: none; } }

.statistics .statisticsinfocard {
  margin-bottom: 22px;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 17px 17px 0 17px;
  width: 100%;
  background-color: #ffffff;
  z-index: 2;
  border-radius: 10px;
  box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2);
  margin-top: 22px; }
  .statistics .statisticsinfocard card-content {
    position: relative; }
    .statistics .statisticsinfocard card-content div {
      transition: all 0.3s ease-in-out; }
    .statistics .statisticsinfocard card-content span {
      position: absolute;
      top: -5px;
      right: -5px; }
  .statistics .statisticsinfocard h3 {
    font-size: 14px;
    margin-bottom: 14px; }
  .statistics .statisticsinfocard p {
    font-size: 12px; }

.statistics .subcategoriescontainer {
  margin: auto;
  max-width: 1000px;
  margin-bottom: 22px; }
  .statistics .subcategoriescontainer .subcategories {
    margin-right: -15px;
    margin-left: -15px; }
    .statistics .subcategoriescontainer .subcategories .MuiCard-root {
      padding: 17px 17px 0 17px;
      width: 100%;
      background-color: #ffffff;
      z-index: 2;
      border-radius: 10px;
      box-shadow: 0 4px 35px 1px rgba(0, 0, 0, 0.2);
      height: 90px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 22px; }
      .statistics .subcategoriescontainer .subcategories .MuiCard-root h1 {
        color: #382e92;
        font-size: 24px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: 0.00938em;
        text-align: center;
        margin-bottom: 16px; }

.statistics .edit-button {
  color: white;
  text-align: center;
  width: 110px;
  float: right;
  margin-bottom: 20px;
  font-size: 12px;
  text-transform: none !important;
  height: 26px;
  line-height: 12px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.28);
  background-image: linear-gradient(253deg, #2ec2cd, var(--blueberry)); }

.statistics .titleannotation {
  font-size: 16px;
  font-weight: 600; }

.statistics .raceSelector {
  width: 260px;
  margin: auto; }
  .statistics .raceSelector .registration-text div:last-child {
    z-index: 10000;
    position: absolute; }

.statistics .filterinputs .MuiInput-root {
  width: 120px;
  margin-left: 20px; }

.statistics .filterinputs .registration-text {
  width: 200px;
  float: left; }
  .statistics .filterinputs .registration-text div:nth-child(3) {
    z-index: 10000;
    display: block; }

.statistics .filterinputs > div:nth-of-type(1) {
  /*margin-right: 40px;*/ }

.statistics .filterinputs > div > div:nth-of-type(1) {
  border-bottom: 1px solid #cccccc;
  border-radius: 0px;
  height: 55px; }

.statistics .landingpage .info-card-body {
  display: block; }

.statistics #sidemenu {
  width: 160px;
  position: fixed;
  right: -10px;
  z-index: 10000;
  top: 400px; }
  .statistics #sidemenu .MuiListItemIcon-root {
    min-width: 34px; }
  .statistics #sidemenu p {
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
    padding: 8px 14px;
    background-color: #f3f3f3; }

.statistics .filter-button {
  margin-top: 12px;
  width: 200px !important;
  padding: 0 30px !important;
  background-image: linear-gradient(263deg, var(--tealish), var(--blueberry));
  position: absolute;
  right: 0px;
  bottom: 14px; }

.statistics .filterArea {
  margin-bottom: 80px; }
  .statistics .filterArea .info-card {
    position: relative;
    z-index: 100; }
  .statistics .filterArea .filterinputs > label {
    margin-left: 20px; }

.statistics .info-card {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
  height: unset; }
  @media (max-width: 1050px) {
    .statistics .info-card {
      max-width: calc(100% - 20px); } }
  .statistics .info-card .helpertext {
    padding-left: 10px;
    clear: both;
    margin-top: 20px; }
  .statistics .info-card .info-card-body-trait {
    width: 100%;
    display: flex;
    min-height: 380px; }
    @media (max-width: 1050px) {
      .statistics .info-card .info-card-body-trait {
        display: unset; } }

.statistics .behaviorCard .info-card-content {
  padding-bottom: 20px; }

.statistics .info-card.small {
  width: fit-content;
  height: 113px;
  margin-top: -168px;
  margin-bottom: 100px;
  overflow: visible; }
  .statistics .info-card.small .info-card-content {
    height: 150px; }
    .statistics .info-card.small .info-card-content .registration-text > div {
      cursor: pointer; }

.statistics .info-card.center.config {
  min-width: unset;
  transition: width linear 300ms, height cubic-bezier(0.4, 0, 1, 1) 300ms; }
  .statistics .info-card.center.config img {
    height: 24px; }
  .statistics .info-card.center.config .info-card-header:hover {
    cursor: pointer; }
  .statistics .info-card.center.config .info-card-text-header {
    font-size: 18px; }

.statistics .info-card.individualtraits {
  margin-top: -100px; }
  .statistics .info-card.individualtraits .info-card-body {
    display: block; }

.statistics .info-card-body-left {
  width: 40%; }
  @media (max-width: 940px) {
    .statistics .info-card-body-left {
      width: 100%; } }

.statistics .info-card-body-right {
  padding-top: 30px; }

.statistics .info-title {
  font-size: 22px; }

.statistics .chart-container {
  padding: 20px; }

.statistics .doggo-image-container-withcontent {
  margin-top: -100px; }

.statistics hr {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: var(--tangerine); }

.statistics .description {
  position: absolute;
  top: 300px;
  width: 100%; }
  .statistics .description .info-text-container {
    letter-spacing: 0.5px;
    margin-top: -180px;
    color: white;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif; }

.statistics .statistics-diclaimer {
  margin: auto;
  text-align: center;
  margin-bottom: 26px;
  margin-top: 26px;
  padding: 20px; }
  .statistics .statistics-diclaimer .info-text-container {
    margin: auto;
    max-width: 940px; }
    .statistics .statistics-diclaimer .info-text-container p {
      text-align: left; }

.statistics .accordion .accordion-container button {
  width: 100%;
  padding: 10px 26px;
  background-color: white;
  border: none;
  border-bottom: 1px solid var(--tangerine);
  color: #382e92;
  font-size: 22px;
  text-align: left;
  font-weight: 600; }

.statistics .accordion .accordion-container button:focus {
  border: none;
  border-bottom: 1px solid var(--tangerine);
  outline: none; }

.statistics .cardmenucontainer {
  max-width: 1000px;
  margin: auto; }

.statistics .menucard {
  text-align: center;
  cursor: pointer; }
  .statistics .menucard .menucard-main-image {
    margin-bottom: 20px; }
  .statistics .menucard h1 {
    color: #382e92;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 700;
    line-height: 0.75;
    letter-spacing: 0.00938em; }
  .statistics .menucard p {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.5px;
    font-size: 14px;
    color: #464646;
    margin-bottom: 0px; }
  .statistics .menucard .info-card-content {
    cursor: pointer; }
  .statistics .menucard .info-card-content:hover {
    background-color: #f7f7f7; }
  .statistics .menucard .info-card-content.selected {
    border: 3px solid;
    cursor: default; }
  .statistics .menucard .menucardlock {
    display: none; }

.statistics .menucard.disabled {
  box-shadow: 0px 0px 4px #c5c5c5; }
  .statistics .menucard.disabled .info-card-content:hover {
    background-color: white; }
  .statistics .menucard.disabled .menucardlock {
    display: block;
    position: absolute;
    top: -36px;
    right: -27px;
    transform: rotate(-18deg); }
    .statistics .menucard.disabled .menucardlock .lockimage {
      width: 40px;
      transition: filter,width 100ms linear;
      cursor: pointer; }
    .statistics .menucard.disabled .menucardlock .lockimage:hover {
      width: 44px;
      filter: blur(1px); }
    @media (max-width: 600px) {
      .statistics .menucard.disabled .menucardlock {
        top: -9px;
        right: 23px; } }

.statistics .comingcard .info-card-content {
  background-color: #f5f5f5; }

.statistics .comingcard .comingcard-image {
  float: right;
  margin-top: -10px; }

.statistics .comingcard .comingcard-main-image {
  width: 70px;
  margin-top: 14px; }

.statistics .comingcard .info-card-body {
  clear: both;
  padding-left: 22px;
  padding-bottom: 6px; }
  .statistics .comingcard .info-card-body p {
    margin-top: 20px;
    color: #949494; }

.statistics .comingcard .info-card-text-header {
  color: #949494;
  font-size: 22px;
  font-weight: 600;
  float: left; }

.statistics .comingcard .info-card-header {
  display: block; }

.statistics .graphcontainer {
  width: calc(50% - 20px);
  float: right; }
  @media (max-width: 900px) {
    .statistics .graphcontainer {
      width: 100%; } }

.statistics .graphcontainer.left {
  float: left; }
  @media (max-width: 900px) {
    .statistics .graphcontainer.left {
      margin-bottom: 40px; } }

.status .header {
  margin-bottom: 0px;
  height: 400px; }
  .status .header img.headerImage {
    margin-bottom: 0px; }

@media (min-width: 800px) {
  .status .phoneonly {
    display: none; } }

@media (max-width: 700px) {
  .status .breedstatuslist .MuiListItem-root {
    align-items: baseline; } }

@media (max-width: 700px) {
  .status .breedstatuslist .MuiListItemSecondaryAction-root {
    transform: unset;
    overflow: hidden;
    position: inherit; } }

.status .edit-button {
  color: white;
  text-align: center;
  width: 110px;
  float: right;
  margin-bottom: 20px;
  font-size: 12px;
  text-transform: none !important;
  height: 26px;
  line-height: 12px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.28);
  background-image: linear-gradient(253deg, #2ec2cd, var(--blueberry)); }

.status .titleannotation {
  font-size: 16px;
  font-weight: 600; }

.status .raceSelector {
  width: 260px;
  margin: auto; }
  .status .raceSelector .registration-text div:last-child {
    z-index: 10000;
    position: absolute; }

.status .filterinputs .MuiInput-root {
  width: 120px;
  margin-left: 20px; }

.status .filterinputs .registration-text {
  width: 200px;
  float: left; }
  .status .filterinputs .registration-text div:nth-child(3) {
    z-index: 10000;
    display: block; }

.status .filterinputs > div:nth-of-type(1) {
  margin-right: 40px; }

.status .filterinputs > div > div:nth-of-type(1) {
  border-bottom: 1px solid #cccccc;
  border-radius: 0px;
  height: 55px; }

.status .landingpage .info-card-body {
  display: block; }

.status #sidemenu {
  width: 160px;
  position: fixed;
  right: -10px;
  z-index: 10000;
  top: 400px; }
  .status #sidemenu .MuiListItemIcon-root {
    min-width: 34px; }
  .status #sidemenu p {
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
    padding: 8px 14px;
    background-color: #f3f3f3; }

.status .breed-dropdown {
  z-index: 900; }
  .status .breed-dropdown div {
    cursor: pointer; }

.status .filter-button {
  margin-top: 12px;
  width: 200px !important;
  padding: 0 30px !important;
  background-image: linear-gradient(263deg, var(--tealish), var(--blueberry));
  position: absolute;
  right: 0px;
  bottom: 14px; }

.status .filterArea {
  margin-bottom: 80px; }
  .status .filterArea .info-card {
    position: relative;
    z-index: 100; }
  .status .filterArea .filterinputs > label {
    margin-left: 20px; }

.status .info-card {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
  height: unset; }
  @media (max-width: 1050px) {
    .status .info-card {
      max-width: calc(100% - 20px); } }
  .status .info-card .helpertext {
    padding-left: 10px;
    clear: both;
    margin-top: 20px; }
  .status .info-card .info-card-body-trait {
    width: 100%;
    display: flex;
    min-height: 380px; }
    @media (max-width: 1050px) {
      .status .info-card .info-card-body-trait {
        display: unset; } }

.status .behaviorCard .info-card-content {
  padding-bottom: 20px; }

.status .info-card.small {
  width: fit-content;
  height: 113px;
  margin-top: -168px;
  margin-bottom: 100px;
  overflow: visible; }
  .status .info-card.small .info-card-content {
    height: 150px; }
    .status .info-card.small .info-card-content .registration-text > div {
      cursor: pointer; }

.status .info-card.center.config {
  min-width: unset;
  transition: width linear 300ms, height cubic-bezier(0.4, 0, 1, 1) 300ms; }
  .status .info-card.center.config img {
    height: 24px; }
  .status .info-card.center.config .info-card-header:hover {
    cursor: pointer; }
  .status .info-card.center.config .info-card-text-header {
    font-size: 18px; }

.status .info-card.individualtraits {
  margin-top: -100px; }
  .status .info-card.individualtraits .info-card-body {
    display: block; }

.status .info-card-body-left {
  width: 40%; }
  @media (max-width: 940px) {
    .status .info-card-body-left {
      width: 100%; } }

.status .info-card-body-right {
  padding-top: 30px; }

.status .info-title {
  font-size: 22px; }

.status .chart-container {
  padding: 20px; }

.status .doggo-image-container-withcontent {
  margin-top: -100px; }

.status hr {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: var(--tangerine); }

.status .description {
  position: absolute;
  top: 300px;
  width: 100%; }
  .status .description .info-text-container {
    letter-spacing: 0.5px;
    margin-top: -180px;
    color: white;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif; }

.status .statistics-diclaimer {
  margin: auto;
  text-align: center;
  margin-bottom: 26px;
  margin-top: 26px;
  padding: 20px; }
  .status .statistics-diclaimer .info-text-container {
    margin: auto;
    max-width: 940px; }
    .status .statistics-diclaimer .info-text-container p {
      text-align: left; }

.status .accordion .accordion-container button {
  width: 100%;
  padding: 10px 26px;
  background-color: white;
  border: none;
  border-bottom: 1px solid var(--tangerine);
  color: #382e92;
  font-size: 22px;
  text-align: left;
  font-weight: 600; }

.status .accordion .accordion-container button:focus {
  border: none;
  border-bottom: 1px solid var(--tangerine);
  outline: none; }

.status .cardmenucontainer {
  max-width: 1000px;
  margin: auto; }

.status .menucard {
  text-align: center; }
  .status .menucard .menucard-main-image {
    margin-bottom: 20px; }
  .status .menucard h1 {
    color: #382e92;
    font-size: 24px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 700;
    line-height: 0.75;
    letter-spacing: 0.00938em; }
  .status .menucard p {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.5px;
    font-size: 14px;
    color: #464646;
    margin-bottom: 0px; }
  .status .menucard .info-card-content {
    cursor: pointer; }
  .status .menucard .info-card-content:hover {
    background-color: #f7f7f7; }
  .status .menucard .info-card-content.selected {
    background-color: #6cefa117;
    cursor: default; }
  .status .menucard .menucardlock {
    display: none; }

.status .menucard.disabled {
  box-shadow: 0px 0px 4px #c5c5c5; }
  .status .menucard.disabled .info-card-content {
    cursor: default; }
  .status .menucard.disabled .info-card-content:hover {
    background-color: white; }
  .status .menucard.disabled .menucardlock {
    display: block;
    position: absolute;
    top: -36px;
    right: -27px;
    transform: rotate(-18deg); }
    .status .menucard.disabled .menucardlock .lockimage {
      width: 40px;
      transition: filter,width 100ms linear;
      cursor: pointer; }
    .status .menucard.disabled .menucardlock .lockimage:hover {
      width: 44px;
      filter: blur(1px); }
    @media (max-width: 600px) {
      .status .menucard.disabled .menucardlock {
        top: -9px;
        right: 23px; } }

.status .comingcard .info-card-content {
  background-color: #f5f5f5; }

.status .comingcard .comingcard-image {
  float: right;
  margin-top: -10px; }

.status .comingcard .comingcard-main-image {
  width: 70px;
  margin-top: 14px; }

.status .comingcard .info-card-body {
  clear: both;
  padding-left: 22px;
  padding-bottom: 6px; }
  .status .comingcard .info-card-body p {
    margin-top: 20px;
    color: #949494; }

.status .comingcard .info-card-text-header {
  color: #949494;
  font-size: 22px;
  font-weight: 600;
  float: left; }

.status .comingcard .info-card-header {
  display: block; }

.status .graphcontainer {
  width: calc(50% - 20px);
  float: right; }
  @media (max-width: 900px) {
    .status .graphcontainer {
      width: 100%; } }

.status .graphcontainer.left {
  float: left; }
  @media (max-width: 900px) {
    .status .graphcontainer.left {
      margin-bottom: 40px; } }

.purchase {
  width: 600px;
  margin: auto;
  box-shadow: 0px 0px 20px 0px #c1c1c1; }
  @media (min-width: 700px) {
    .purchase {
      width: 100%; } }

.measureflipper {
  overflow: auto; }
  .measureflipper > h5 {
    text-align: center;
    margin-top: 20px; }
  .measureflipper div {
    width: calc(50% - 20px);
    float: left;
    text-align: center;
    background-color: #e5e2ff;
    margin: 10px;
    padding: 10px;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer; }
    .measureflipper div.selected {
      background-color: #382ea0;
      color: white;
      cursor: default; }
    .measureflipper div p {
      margin-bottom: 0px; }

.dog-proportion-explainimage > div {
  text-align: center; }

.lectures-container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: row; }
  .lectures-container .lecturecard {
    padding: 17px 17px 0 17px;
    margin: 20px;
    width: calc(33% - 40px);
    cursor: pointer;
    border: 3px solid transparent;
    transition: all 0.3s ease-in-out; }
    .lectures-container .lecturecard .lecturecard-header .card-title h1 {
      font-weight: bold;
      font-size: 26px; }
    .lectures-container .lecturecard .lecturecard-content p {
      font-size: 14px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif; }
    .lectures-container .lecturecard .lecturecard-content .card-image img {
      width: 100%; }
    .lectures-container .lecturecard:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
      border: 3px solid lightgreen; }

.lectureplayermodal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #000000ab;
  text-align: center;
  z-index: 100000; }
  .lectureplayermodal .lectureplayerheader {
    position: relative; }
    .lectureplayermodal .lectureplayerheader .card-title {
      margin: 0px; }
      .lectureplayermodal .lectureplayerheader .card-title p {
        font-size: 30px; }
    .lectureplayermodal .lectureplayerheader .closebtn {
      position: absolute;
      top: -18px;
      right: -12px;
      padding: 10px;
      font-weight: 600;
      font-size: 20px;
      font-family: cursive;
      cursor: pointer; }
      .lectureplayermodal .lectureplayerheader .closebtn:hover {
        color: gray; }
  .lectureplayermodal .lectureplayercontent {
    max-width: 1000px;
    padding: 20px;
    background-color: white;
    margin: auto;
    margin-top: 80px; }
    .lectureplayermodal .lectureplayercontent .lectureplayer video {
      width: 100%; }

.hover-pointer {
  cursor: pointer; }

.breedselector {
  display: block;
  z-index: 100; }

.purchase {
  width: 600px;
  margin: auto;
  box-shadow: 0px 0px 20px 0px #c1c1c1; }
  @media (min-width: 700px) {
    .purchase {
      width: 100% !important; } }

.add-button-enabled {
  margin-top: 12px;
  padding: 0 30px !important;
  height: 40px;
  border-radius: 35px;
  color: white;
  background-image: linear-gradient(263deg, var(--tealish), var(--blueberry)); }

.registercard {
  border: none; }

.registercard.disabled {
  display: none; }

.changepaymentbtn {
  bottom: 16px;
  right: 70px;
  position: absolute; }
  @media (max-width: 900px) {
    .changepaymentbtn {
      position: unset !important;
      margin-top: 30px !important;
      float: none !important; } }

.productlist {
  padding: 0px 20px;
  /* NYHETSBANNER */
  /* common */
  /* top right*/ }
  .productlist .col {
    flex-basis: unset; }
  .productlist .box {
    /*position: relative;
        max-width: 600px;
        width: 90%;
        height: 400px;
        background: #fff;
        box-shadow: 0 0 15px rgba(0,0,0,.1);*/ }
  .productlist .ribbon {
    width: 128px;
    height: 128px;
    overflow: hidden;
    position: absolute; }
  .productlist .ribbon::before,
  .productlist .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #382e92; }
  .productlist .ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 11px 0;
    background-color: #4a3fa8;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #fff;
    font: 700 16px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center; }
  .productlist .ribbon-top-right {
    top: -10px;
    right: 5px;
    z-index: 20; }
  .productlist .ribbon-top-right::before,
  .productlist .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent; }
  .productlist .ribbon-top-right::before {
    top: 0;
    left: 0; }
  .productlist .ribbon-top-right::after {
    bottom: 0;
    right: 0; }
  .productlist .ribbon-top-right span {
    left: -35px;
    top: 32px;
    transform: rotate(45deg); }

.langswitchers {
  padding: 4px !important;
  background-color: transparent !important;
  cursor: unset !important;
  margin-right: 20px !important; }
  .langswitchers img {
    width: 30px;
    margin-left: 12px;
    display: flex;
    cursor: pointer; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body,
#app,
#app > div {
  height: 100%;
  width: 100%; }

html {
  scroll-behavior: smooth; }

:root {
  --slate: #4b4f63;
  --tangerine: #ff8b12;
  --blueberry: #382e92;
  --tealish: #30acc4; }

.divider {
  background-color: var(--tangerine) !important;
  height: 2px; }

body {
  font-family: sans-serif; }

.everything .wrapper {
  overscroll-behavior: none; }

.mobile {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 160px;
  padding-bottom: 160px; }

.MuiFormHelperText-root.Mui-error {
  color: #dc3545 !important; }

.flagselector {
  padding-top: 7px;
  float: left; }
  .flagselector button {
    border: none; }
    .flagselector button::after {
      content: "" !important;
      display: none !important; }
  .flagselector svg {
    width: 25px; }
