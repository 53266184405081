.wizardscontainer table {
  border: 1px solid #dddddd;
  margin-top: 28px; }
  .wizardscontainer table tr {
    padding: 16px 10px;
    display: block;
    border-bottom: 1px solid lightgray; }
    .wizardscontainer table tr:nth-child(even) {
      background-color: #f7f7f7; }
    .wizardscontainer table tr td h3 {
      float: left;
      font-size: 19px;
      margin-left: 15px;
      margin-bottom: 3px; }

.wizinfopage .wizard-card-header {
  width: 100%; }
  .wizinfopage .wizard-card-header > h6 {
    margin-top: 4px !important; }

.wizinfopage .MuiAccordionDetails-root > div {
  width: 100%; }
  .wizinfopage .MuiAccordionDetails-root > div > p {
    padding-right: 30px;
    margin-bottom: 18px; }

.wizinfopage .MuiCollapse-container {
  border-top: 1px solid var(--tangerine); }

.wizardTitleContainer h2 {
  color: #382e92;
  font-size: 24px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  line-height: 1.75;
  letter-spacing: 0.00938em;
  border-bottom: 1px solid var(--tangerine);
  margin-bottom: 20px; }

.wizardTitleContainer h6 {
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin-bottom: 60px; }

@media (max-width: 850px) {
  .wizardTitleContainer {
    padding: 20px; } }

.wizardscontainer td {
  display: block; }

.wizardscontainer .titlearea {
  overflow: hidden; }

.wizardscontainer .buttonarea {
  display: flex;
  width: 100%;
  margin-top: 10px; }
  .wizardscontainer .buttonarea button {
    width: auto;
    height: unset;
    padding: 4px 16px !important;
    border-radius: 6px;
    margin: 0px; }
    @media (min-width: 850px) {
      .wizardscontainer .buttonarea button {
        margin-left: 20px; } }
  @media (max-width: 850px) {
    .wizardscontainer .buttonarea {
      justify-content: space-around; } }

.checkboxes-container {
  text-align: center; }
  .checkboxes-container > label {
    flex-direction: unset;
    width: 100%;
    align-items: baseline;
    max-width: 95%; }
    .checkboxes-container > label .MuiTypography-body1 {
      position: relative;
      top: -6px;
      text-align: left; }

.dropdown-container {
  padding: 0px 40px; }

.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded {
  max-height: 350px !important; }

.freetext-content-wrapper .freetext-textfield > div {
  width: 100%; }

.dog-wizard-slider-wrapper .MuiSlider-markLabel {
  width: 100px;
  white-space: normal;
  line-break: normal; }

.dog-wizard-slider-wrapper > span {
  height: 38px; }

.dog-wizard-slider-wrapper .slidermarks {
  margin-top: -48px;
  margin-bottom: 50px; }
  .dog-wizard-slider-wrapper .slidermarks .markleft {
    width: calc(50% - 40px);
    float: left;
    font-size: 12px;
    margin-left: -20px; }
  .dog-wizard-slider-wrapper .slidermarks .markright {
    width: auto;
    max-width: calc(50% - 40px);
    float: right;
    font-size: 12px;
    margin-right: -20px; }

.dog-wizard-slider-wrapper .notselectedvalue .MuiSlider-thumb {
  background-color: white;
  border: 2px solid; }

.dog-wizard-slider-wrapper .MuiSlider-markLabel {
  text-align: center; }

.dog-wizard-slider-wrapper .MuiSlider-mark {
  height: 8px;
  margin-left: -1px;
  background-color: #382e92; }

.videorecorder .video-js {
  width: 100%;
  height: 100%; }

.MuiAccordion-root.Mui-disabled {
  background-color: transparent !important; }

.MuiCardContent-root ol {
  padding: 0px 30px;
  padding-bottom: 0px;
  margin-bottom: 0px; }

.MuiCardContent-root table {
  width: 100%; }
