.MuiFormControl-root {
  width: 100%; }

.dog-gender-container {
  display: flex;
  justify-content: space-around; }

.dog-gender-header {
  text-align: center;
  margin-bottom: 6px;
  color: #382e92;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px; }

.wizfinished-registrationform {
  padding: 0 42px;
  margin-bottom: 20px; }

.hover-pointer {
  text-align: center;
  margin: 30px 0px !important; }

.MuiPickersDatePickerRoot-toolbar .MuiTypography-subtitle1::after {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  content: "\21E6 Select year";
  font-size: 16px;
  padding-left: 8px;
  font-weight: 400; }
