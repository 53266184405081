.languageswitcher {
    width: 340px;
    float: right;
    margin-bottom: 20px;
    margin-left: 20px;
    .registration-text>div>div>div {
        color: #38389c;
        font-weight: 600;
    }
}

.wizinfocontainer {
    margin-top: 90px;
    ul {
        margin-left: 20px;
    }
}

.card-content {
    ul {
        margin-left: 20px;
    }
}

.MuiMobileStepper-root {
    height: 60px;
    button:nth-child(1) {
        float: left;
        margin-top: 12px;
    }
    .MuiMobileStepper-dots {
        //position: absolute;
        top: 8px;
        width: 100vw;
        max-width: 800px;
        justify-content: center;
    }
    button:nth-child(3) {
        float: right;
        margin-top: 12px;
    }
}