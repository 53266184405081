.dogexplorer {
  width: 100%;
  padding: 0px 60px; }
  .dogexplorer .MuiCard-root {
    padding: 20px;
    box-shadow: 1px 1px 8px 0px #cdcdcd; }
    .dogexplorer .MuiCard-root h1 {
      font-size: 24px;
      border-bottom: 1px solid #e57d10;
      padding-bottom: 20px; }
  .dogexplorer .MuiAccordionDetails-root, .dogexplorer .MuiAccordionSummary-content {
    display: block; }
  .dogexplorer .MuiAccordionSummary-content h3 {
    font-weight: 600;
    font-size: 22px; }
  .dogexplorer .MuiAccordionSummary-content hr {
    margin-top: 6px; }
  .dogexplorer .dogexplorer-profile {
    margin-top: 40px; }
    .dogexplorer .dogexplorer-profile .loading {
      background-color: #ffffffd6;
      width: 100%;
      height: calc(100% - 105px);
      position: absolute;
      left: 0px;
      top: 105px; }
      .dogexplorer .dogexplorer-profile .loading p {
        text-align: center;
        margin-top: 50vh;
        font-size: 26px; }
      .dogexplorer .dogexplorer-profile .loading.hidden {
        display: none;
        width: 0%; }
    .dogexplorer .dogexplorer-profile .dogexplorer-profilecard-children td:hover {
      text-decoration: underline; }
    .dogexplorer .dogexplorer-profile .dogexplorer-profilecard-huntingresults table thead {
      border-bottom: 1px solid #382e92; }
    .dogexplorer .dogexplorer-profile .dogexplorer-profilecard-huntingresults table tbody tr:nth-child(odd) {
      background-color: #eae8ff; }
    .dogexplorer .dogexplorer-profile .dogexplorer-profilecard-general tbody tr:nth-child(odd) {
      background-color: #eae8ff; }
    .dogexplorer .dogexplorer-profile .dogexplorer-profilecard-general tbody tr td:nth-child(1) {
      padding-left: 6px; }
  .dogexplorer .dogexplorer-gallery {
    width: 100%;
    padding-top: 30px; }
    .dogexplorer .dogexplorer-gallery .dogexplorer-gallery-dog {
      width: 200px;
      float: left;
      margin: 15px;
      box-shadow: 0px 0px 10px #d4d4d4;
      border-radius: 8px; }
      .dogexplorer .dogexplorer-gallery .dogexplorer-gallery-dog p {
        margin: 0px; }
      .dogexplorer .dogexplorer-gallery .dogexplorer-gallery-dog .imagecontainer {
        width: 180px;
        height: 180px;
        margin-left: 10px;
        margin-top: -10px;
        box-shadow: 0px 0px 4px #c3c3c3;
        border-radius: 8px;
        overflow: hidden;
        background-color: #ececec;
        cursor: pointer;
        transition: all 100ms linear; }
        .dogexplorer .dogexplorer-gallery .dogexplorer-gallery-dog .imagecontainer:hover {
          width: 186px;
          height: 186px;
          margin-left: 7px;
          margin-top: -16px; }
        .dogexplorer .dogexplorer-gallery .dogexplorer-gallery-dog .imagecontainer img {
          width: 100%;
          object-fit: cover; }
      .dogexplorer .dogexplorer-gallery .dogexplorer-gallery-dog hr {
        margin-top: 2px;
        margin-bottom: 0px; }
      .dogexplorer .dogexplorer-gallery .dogexplorer-gallery-dog .titlecontainer {
        color: #382e92;
        padding: 0px 10px;
        font-size: 16px;
        margin-top: 6px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif; }
        .dogexplorer .dogexplorer-gallery .dogexplorer-gallery-dog .titlecontainer p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .dogexplorer .dogexplorer-gallery .dogexplorer-gallery-dog .titlecontainer p:hover {
            width: fit-content;
            background-color: white;
            padding: 0px 8px 0px 0px;
            display: block;
            position: relative; }
      .dogexplorer .dogexplorer-gallery .dogexplorer-gallery-dog .textcontainer {
        color: #382e92;
        padding: 0px 10px;
        font-size: 13px;
        margin-top: 6px;
        margin-bottom: 5px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif; }
  .dogexplorer .dogexplorer-filter {
    margin: 16px;
    box-shadow: 0px 0px 16px #c8c8c8;
    border-radius: 6px;
    padding: 20px; }
    .dogexplorer .dogexplorer-filter .MuiInput-root {
      margin-left: unset; }
    .dogexplorer .dogexplorer-filter .filterinputs .registration-text {
      width: 100%; }
    .dogexplorer .dogexplorer-filter .filterinputs .MuiFormControl-root.MuiTextField-root {
      width: 100%;
      border: none; }
      .dogexplorer .dogexplorer-filter .filterinputs .MuiFormControl-root.MuiTextField-root .MuiInputBase-formControl {
        width: 100%; }
    .dogexplorer .dogexplorer-filter .filterinputs .birthyearfilters .MuiFormControl-root.MuiTextField-root {
      width: 50%; }
      .dogexplorer .dogexplorer-filter .filterinputs .birthyearfilters .MuiFormControl-root.MuiTextField-root:nth-child(1) {
        padding-right: 20px; }
    .dogexplorer .dogexplorer-filter .filterbutton {
      margin-top: 20px; }
    .dogexplorer .dogexplorer-filter .Mui-disabled {
      color: black;
      background-image: linear-gradient(253deg, #99b7b9, #aeacbb); }
    .dogexplorer .dogexplorer-filter .selectedfilters .selectedfilter {
      width: fit-content;
      float: left;
      background-color: #382e92;
      margin-top: 10px;
      color: white;
      padding: 3px 14px;
      border-radius: 16px;
      margin-right: 8px; }
      .dogexplorer .dogexplorer-filter .selectedfilters .selectedfilter p {
        width: fit-content;
        margin-bottom: 0px; }
        .dogexplorer .dogexplorer-filter .selectedfilters .selectedfilter p svg {
          height: 19px;
          width: 21px;
          margin-bottom: 3px;
          cursor: pointer; }
  .dogexplorer .dogexplorer-displayarea {
    margin: 16px;
    margin-top: 80px;
    box-shadow: 0px 0px 16px #c8c8c8;
    border-radius: 6px;
    padding: 20px; }
    .dogexplorer .dogexplorer-displayarea .cardloader {
      background-color: #ffffffcc;
      z-index: 1000;
      width: 100%;
      position: absolute;
      height: 100%; }
      .dogexplorer .dogexplorer-displayarea .cardloader p {
        text-align: center;
        margin-top: 68px;
        font-size: 22px;
        color: #382e92; }
    .dogexplorer .dogexplorer-displayarea .dogexplorer-displayarea-tabs {
      margin-top: -78px; }
      .dogexplorer .dogexplorer-displayarea .dogexplorer-displayarea-tabs button {
        background-color: white;
        margin-top: 10px;
        border-radius: 4px 4px 0px 0px;
        box-shadow: 0px 0px 10px 0px #c2c2c2;
        margin-right: 8px;
        margin-left: 8px; }
        .dogexplorer .dogexplorer-displayarea .dogexplorer-displayarea-tabs button:not(.Mui-selected) {
          border-bottom: 1px solid #dfdfdf; }
    .dogexplorer .dogexplorer-displayarea .dogexplorer-typeselector {
      width: 100%;
      border-right: 2px solid #382e92; }
      .dogexplorer .dogexplorer-displayarea .dogexplorer-typeselector .typebutton {
        background-color: #ffffff;
        border: 1px solid #dcdae8;
        border-right: none;
        padding: 12px;
        margin-bottom: 10px;
        margin-left: 10px;
        transition: margin-left 100ms linear;
        border-radius: 6px 0px 0px 6px;
        cursor: pointer; }
        .dogexplorer .dogexplorer-displayarea .dogexplorer-typeselector .typebutton.selected {
          margin-left: 0px;
          background-color: #f7f7f7; }
          .dogexplorer .dogexplorer-displayarea .dogexplorer-typeselector .typebutton.selected:hover {
            background-color: #ebebeb; }
        .dogexplorer .dogexplorer-displayarea .dogexplorer-typeselector .typebutton:hover {
          margin-left: 0px;
          background-color: #f7f7f7; }
        .dogexplorer .dogexplorer-displayarea .dogexplorer-typeselector .typebutton p {
          margin-bottom: 0px;
          cursor: pointer;
          color: #382e92;
          font-size: 20px;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          font-weight: normal; }
    .dogexplorer .dogexplorer-displayarea .dogexplorer-statistics {
      margin-top: 40px; }
      .dogexplorer .dogexplorer-displayarea .dogexplorer-statistics .MuiCard-root.dogexplorer-card-number p {
        font-size: 56px;
        text-align: center; }
    .dogexplorer .dogexplorer-displayarea .MuiTabs-root button:focus {
      outline: none; }
  .dogexplorer h3 {
    font-weight: bold;
    font-size: 26px;
    color: #382e92; }
  .dogexplorer .edit-button {
    float: unset; }
